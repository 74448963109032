export const categories = [
    {
        'name': 'Polizza Auto',
        'value': 'Polizza_Auto_PA'
    },
    {
        'name': 'Cauzione Provvisoria AP',
        'value': 'Cauzione_Provvisoria_AP'
    },
    {
        'name': 'Cauzione Definitiva AP',
        'value': 'Cauzione_Definitiva_AP'
    },
    {
        'name': 'Cauzione Anticipazione AP',
        'value': 'Cauzione_Anticipazione_AP'
    },
]

export const  subMenu = {
  'Polizza_Auto_PA': [
    {
        'name': 'Prima',
        'value': '1A'
    },
    {
        'name': 'Axa',
        'value': '1Q'
    },
    /*{
        'name': 'Bene',
        'value': '1C'
    },
    {
        'name': 'Tua',
        'value': '1D'
    },
    {
        'name': 'Itas',
        'value': '1E'
    },
    {
        'name': 'Genial',
        'value': '1F'
    },
    {
        'name': 'Groupama',
        'value': '1G'
    },
    {
        'name': 'hdi',
        'value': '1H'
    },
    {
        'name': 'Unipol',
        'value': '1I'
    },
    {
        'name': 'Vittoria',
        'value': '1J'
    },
    {
        'name': 'Cattolica',
        'value': '1K'
    },
    {
        'name': 'Allianz',
        'value': '1L'
    },
    {
        'name': 'Sara',
        'value': '1M'
    },
    {
        'name': 'Generali',
        'value': '1N'
    },
    {
        'name': 'Nobis',
        'value': '1O'
    },
    {
        'name': 'Zurich',
        'value': '1P'
    },*/
 ],
    'Cauzione_Provvisoria_AP': [
        {
            'name':'Klpp',
            'value': '2A',
            'actual_value': 'CP_Klpp'
        },
        {
            'name':'Accellerant',
            'value': '2B',
            'actual_value': 'CP_Accellerant'
        },
        /*{
            'name':'Sace',
            'value': '2C',
            'actual_value': 'CP_Sace'
        },
        {
            'name':'Bene',
            'value': '2D',
            'actual_value': 'CP_Bene'
        },
        {
            'name':'Over',
            'value': '2E',
            'actual_value': 'CP_Over'
        },*/
        {
            'name':'Great America',
            'value': '2F',
            'actual_value': 'CP_GreatAmerica'
        },
    ],
    'Cauzione_Definitiva_AP': [
        {
            'name':'Klpp',
            'value': '3A',
            'actual_value': 'CD_Klpp'
        },
        {
            'name':'Accellerant',
            'value': '3B',
            'actual_value': 'CD_Accellerant'
        },
        {
            'name':'Itas',
            'value': '3C',
            'actual_value': 'CD_Itas'
        },
        /*{
            'name':'Bene',
            'value': '3D',
            'actual_value': 'CD_Bene'
        },
        {
            'name':'Over',
            'value': '3E',
            'actual_value': 'CD_Over'
        },*/
        {
            'name':'Great America',
            'value': '3F',
            'actual_value': 'CD_GreatAmerica'
        },
    ],
    'Cauzione_Anticipazione_AP': [
        {
            'name':'Klpp',
            'value': '4A',
            'actual_value': 'CA_Klpp'
        },
         {
            'name':'Accellerant',
            'value': '4B',
            'actual_value': 'CA_Accellerant'
        },
        /*{
            'name':'Sace',
            'value': '4C',
            'actual_value': 'CA_Sace'
        },
        {
            'name':'Bene',
            'value': '4D',
            'actual_value': 'CA_Bene'
        },
        {
            'name':'Over',
            'value': '4E',
            'actual_value': 'CA_Over'
        }*/
        {
            'name':'Great America',
            'value': '4F',
            'actual_value': 'CA_GreatAmerica'
        },
    ]
}

export const elabList = [
    {
        'name': 'TRUE',
        'value': 1 
    },
    {
        'name': 'FALSE',
        'value': 0
    }
]

export const fileTypes = ["PDF"];

export const TipoFile = [
    {
        'name': 'GEOFENCING',
        'value': 'geofencing'
    },
    {
        'name': 'EXTRACANONI',
        'value': 'extraCanoni'
    },
    {
        'name': 'FUEL CARD ENI',
        'value': 'fuelcardeni'
    },
    {
        'name': 'FUEL CARD TBS',
        'value': 'fuelcardtbs'
    },
    {
        'name': 'BOLLO E CANONI BB',
        'value': 'bolloCanoniBB'
    },
    {
        'name': 'PRENOTAZIONI AUTO IN POOL',
        'value': 'prenotazioniAutoInPool'
    },
    {
        'name': 'TELEPASS TBUSINESS',
        'value': 'movimentiTelepassTBusiness'
    },
    {
        'name': 'TELEPASS BLOOMFLEET',
        'value': 'movimentiTelepassFleet'
    },
    {
        'name': 'MULTE',
        'value': 'multe'
    },
    {
        'name': 'CARTE JOLLY',
        'value': 'carteJolly'
    },
    {
        'name': 'FEE TELEPASS T-BUSINESS',
        'value': 'feeTelepassTBusiness'
    },
    {
        'name': 'CANONI NBT',
        'value': 'canoniNBT'
    },
    {
        'name': 'FEE TELEPASS BLOOMFLEET',
        'value': 'feeTelepassBloomFleet'
    },
    {
        'name': 'STILE DI GUIDA',
        'value': 'stileguida'
    },
    {
        'name': 'ENELX',
        'value': 'EnelX'
    },
    {
        'name': 'EASYPARK',
        'value': 'EasyPark'
    },
]
