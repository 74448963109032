
import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Flex, Button } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';

import { createFileManager } from "../../graphql/mutations";
import { fileTypes, categories, subMenu, TipoFile } from './category';
import styles from './FIleUploader.module.css';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';


import AlertBox from './Alert';
import { TextField } from '@mui/material';

import { Auth } from 'aws-amplify';

//export default function Upload2iPage() {
  //return (
    //<div>
      //<h1>2Iretegas Page</h1>
      //{/* Contenuto della pagina */}
    //</div>
  //);
//}

const Upload2iPage = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    console.log(selectedFiles)
    const [elab] = useState(0);
    const [state] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fileError, setFileError] = useState('');
    const [uploadedBy, setUploadedBy] = useState('');
  
    const [valoreSceltaMultipla, setValoreSceltaMultipla] = useState('');
  
    const [uploadAlert, setUploadAlert] = useState({
      action: '',
      message: ''
    });
  


    const handleInput = (input_name ,value, itemIndex, selectedOption) => {
      const allItems = selectedFiles.map((fileData, index) => {
        if (index === itemIndex) {
          let fileEntry = {
            ...fileData,
            [input_name]: value,
            rata:
            input_name === 'category'
              ? defaultRataValues[value] || '' // Imposta il valore predefinito di "rata" in base alla "category" selezionata
              : selectedOption !== undefined
              ? selectedOption
              : fileData.rata,
            quietanza: input_name === 'quietanza' ? value : fileData.quietanza,
        };
  
          if(fileEntry.category && fileEntry.subCategory) {
            fileEntry.error = ""
          }
          return fileEntry
        }
        else {
          return fileData
        }
      })
      setSelectedFiles(allItems);
    }
  
    /*const generateUniqueKey = (key, category, subCategory) => {
      const currentUniqeTime = new Date().getTime();
      const keyArray = key?.split('.');
      const keyName = keyArray[0].replace(/\s/g, '');
      const keyExtension = keyArray[1];
      console.log(`${category}_${subCategory}_${keyName}_${currentUniqeTime}.${keyExtension}`)
      return `${category}_${subCategory}_${keyName}_${currentUniqeTime}.${keyExtension}`;
    };*/

    const generateName = (key,category,data) => {
      const currentUniqeTime = new Date().getTime();
      const keyArray = key?.split('.');
      const keyName = keyArray[0].replace(/\s/g, '');
      const keyExtension = keyArray[1];
      

      let fileName = `${"2i"}_${category}_${keyName}.${keyExtension}`;
  
      if (data) {
        const dateParts = data.split('-');
        const formattedDate = `${dateParts[1]}_${dateParts[0]}`;
        console.log(formattedDate);
        fileName = `${"2i"}_${category}_${keyName}_${formattedDate}.${keyExtension}`;
      }

      console.log(`${data}`)
      console.log(`${category}_${keyName}_${currentUniqeTime}.${keyExtension}`)
      console.log(fileName);

      return fileName;
      //return `${"2i"}_${category}_${keyName}_${currentUniqeTime}.${keyExtension}`;
    };
  
    const opzioniSceltaMultipla   = [
      { value: 'Unica soluzione', label: 'Unica soluzione' },
      { value: 'Mensile', label: 'Mensile' },
      { value: 'Bimestrale', label: 'Bimestrale' },
      { value: 'Trimestrale', label: 'Trimestrale' },
      { value: 'Quadrimestrale', label: 'Quadrimestrale' },
      { value: 'Semestrale', label: 'Semestrale' },
      { value: 'Annuale', label: 'Annuale' },
      { value: 'Temporanea', label: 'Temporanea' },
      // Aggiungi altre opzioni se necessario
    ];
  
    const defaultRataValues = {
      Cauzione_Provvisoria_AP: 'Unica soluzione',
      Cauzione_Definitiva_AP: 'Annuale',
      Cauzione_Anticipazione_AP: '',
      // Aggiungi altri valori predefiniti per le altre categorie se necessario
    };
  
    const [quietanza, setQuietanza] = useState('');
    
    const [defaultRata, setDefaultRata] = useState('');
  
    const handleUpload = async () => {
      if (!selectedFiles || selectedFiles.length === 0) {
        setFileError('Please select file')
        console.error('No file selected!');
        return;
      }
      let hasError = false
      let fileMaps = selectedFiles.map(fileEntry => {
        console.log(fileEntry.quietanza)
        //if(!fileEntry.category || !fileEntry.subCategory || !fileEntry.rata || fileEntry.quietanza == undefined ) { Con gestione errori
        if(false) {
          console.log("ops");
          hasError = true;
          console.log(hasError);
          return {
            ...fileEntry,
            error: `Please select Tipo Polizza, Compagnia, Rata and Quietanza for  ${fileEntry.file.name}`
          }
        }
        return fileEntry
      })
      if (hasError) {
        console.log(hasError);
        setSelectedFiles(fileMaps);
        return;
      }
      console.log("I am here", uploadedBy)
      setLoading(true);
      const accessLevel = 'public';
      const newFileList = []
      try {
        console.log("I am here2")
        const fileUploadPromises = selectedFiles.map((selectedFile) => {
          if (!selectedFile?.file.name) {
            
            return;
          }
          // const filename = generateUniqueKey(selectedFile.file.name, selectedFile.category, selectedFile.subCategory) con parametri per creare chiave unica con la funzione
          console.log("I am here3")
          const filename = `2i/${generateName(selectedFile.file.name, selectedFile.category,selectedFile.data)}`;
          console.log("I am here4")
          newFileList.push({...selectedFile, uniqueFileKey: filename})
          return Storage.put(filename, selectedFile.file, { level: accessLevel, download: true })
        });
        const result = await Promise.all(fileUploadPromises)
        console.log('File uploaded successfully!', result);
  
        const uploadedFileKeys = []
        const failedFiles = []
  
  
        result.forEach((file, index) => {
  
          if (file.key) {
            let fileDetails = newFileList.find(fileEntry => file.key.includes(fileEntry.uniqueFileKey))
            uploadedFileKeys.push({
              ...fileDetails,
              s3Key: file.key
            })
          } else {
            failedFiles.push(selectedFiles[index])
          }
        })
        console.log('File processing to s3 completed', {
          "Failed": failedFiles,
          "Sccess": uploadedFileKeys
        });
        setUploadAlert({
          action: 'success',
          message: 'File uploaded successfully!'
        })
        clearInput()
        setLoading(false);
        //createFileEntry(uploadedFileKeys)
      } catch (error) {
        setUploadAlert({
          action: 'error',
          message: 'File uploaded failed! Please try again'
        })
        setLoading(false);
      }
  
    };
  
  
    
  
    /*async function createFileEntry(fileListWithS3Key) {
      setLoading(true);
      console.log("Dynamodb Processing will start...")
  
      const promises = fileListWithS3Key.map(fileEntry => {
        let npagine;
        if (fileEntry.subCategory === '2A' || fileEntry.subCategory === '3A' || fileEntry.subCategory === '4A' || fileEntry.subCategory === '1B' ) {
          npagine = '2';
        } else if (fileEntry.subCategory === '2B' || fileEntry.subCategory === '3B' || fileEntry.subCategory === '4B') {
          npagine = '1';
        } else if (fileEntry.subCategory === '2C' || fileEntry.subCategory === '3C' || fileEntry.subCategory === '4C') {
          npagine = '1'; 
        }else {
          npagine = 'non so';
        }
  
        const createFileInput = {
          key: fileEntry.s3Key,
          category: fileEntry.category,
          subCategory: fileEntry.subCategory,
          elab: elab,
          // state 
          state: state,
          // state 
          consulenza: fileEntry.consulenza,
          value: fileEntry.value,
          // durata 
          durata: fileEntry.durata,
          // durata 
          rata: fileEntry.rata,
          quietanza: fileEntry.quietanza,
          npagine:npagine,
  
          uploadedBy: uploadedBy
          
        };
        return API.graphql(graphqlOperation(createFileManager, { input: createFileInput }))
      })
      try {
        const result = await Promise.all(promises)
        console.log('File uploaded successfully!', result);
        setUploadAlert({
          action: 'success',
          message: 'File uploaded successfully!'
        })
        clearInput()
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setUploadAlert({
          action: 'error',
          message: 'File uploaded failed! Please try again'
        })
        console.error('Error uploading file:', error);
      }
    }*/
  
    const removeFileByIndex = (index) => {
      selectedFiles.splice(index, 1);
      setSelectedFiles([...selectedFiles]);
    }
    const removeAll = () => {
      setSelectedFiles([]);
    }
  
    const clearInput = () => {
      setFileError('');
      setSelectedFiles([]);
    }
  
    const handleAlertClose = () => {
      setUploadAlert({
        action: '',
        message: ''
      })
    }
  
    const handleDrop = (acceptedFiles) => {
      let newFiles = [];
      let skipedFiles = [];
  
      acceptedFiles.forEach((file) => {
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          skipedFiles.push(file);
        } else {
          newFiles.push({file: file, category: '', subCategory: '', consulenza: false, value: 0,durata : 180,rata: '',state:0, error: ''});
        }
      })
      const allFiles = [...selectedFiles, ...newFiles];
      if (allFiles.length > 0) {
        setFileError('')
      }
      if (allFiles.length > 10) {
        setFileError('You can upload maximum 10 files at a time')
        alert('You can upload maximum 10 files at a time');
        return;
      }
      if (skipedFiles.length > 0) {
        alert('WIll only upload xlsx files, skipped other files');
      }
      setSelectedFiles([...allFiles]);
      // Handle file upload process
    };
  
    useEffect( () => {
  
      async function fetchData() {
    
        const user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;
        setUploadedBy(attributes.email)
        console.log(attributes.email)
      }
      fetchData();
  
    }, [])
  
    return (
      <div>
        {
          uploadAlert.message &&
          <AlertBox
            action={uploadAlert.action}
            message={uploadAlert.message}
            handleAlertClose={handleAlertClose}
          />
        }
        <h2>File Uploader</h2>
  
        <div className={styles.drop_area}>
  
          <Dropzone accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.drop_zone_text}>
                <input {...getInputProps()} />
                <p>Drag and drop  file here, or click to select a file</p>
              </div>
            )}
          </Dropzone>
        </div>
  
        <br /><br />
        <div className={styles.input_error}>
          {fileError}
        </div>
        <div className={styles.fileList}>
          {
            selectedFiles.map((file, index) => {
              return (
                <div key={index}>
  
                  <div className={styles.file_select_container}>
  
                  <div className={styles.left_content}>
                    <div className={styles.left_content_grid}>
                      <Grid container spacing={2} style={{ width: '100%' }}  >
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div style={{ wordBreak: 'break-all' }}>{file.file.name}</div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2.6}>
                          <FormControl sx={{ m: 1, minWidth: '100%' ,maxWidth: '400px', }} size='small'>
                            <InputLabel id="demo-simple-select-autowidth-label">Tipo File</InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={file.category}
                              onChange={(e) => handleInput('category', e.target.value, index)}
                              
                              label="category"
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {TipoFile.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                        {(file.category === 'bollocanoniBB' || file.category === 'feeTelepassTBusiness' || file.category === 'stileguida') && (
                          
                        <TextField
                          label="Data"
                          type="month" // Utilizza il tipo "month" per selezionare solo mese e anno
                          value={file.data}
                          onChange={(e) => handleInput('data', e.target.value, index)}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton edge="start" aria-label="calendar">
                                  <CalendarTodayIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                           style: { marginTop: '7px' }
                          }}
                          fullWidth
                          size="small"
                        />
                        )}
                        </Grid>
                        
                        {/*<Grid item xs={12} sm={6} md={6} lg={1.7}>
                          <FormControl sx={{ m: 1, minWidth: '100%', maxWidth: '200px' }} size='small'>
                            <InputLabel id="demo-simple-select-autowidth-label">Compagnia</InputLabel>
                            <Select
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              value={file.subCategory}
                              onChange={(e) => handleInput('subCategory', e.target.value, index)}
                              width="100%"
                              label="subMenu"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {file.category && subMenu[file.category].map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                          <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                            <InputLabel id="etichetta-scelta-multipla">Rata</InputLabel>
                            <Select
                              labelId="etichetta-scelta-multipla"
                              id="campo-scelta-multipla"
                              value={selectedFiles[index].rata}
                              onChange={(e) => handleInput('rata', '', index, e.target.value)}
                              width="100%"
                              label="rata"
                            >
                              <MenuItem value="">
                                <em>Nessuna</em>
                              </MenuItem>
                              {opzioniSceltaMultipla.map((opzione) => (
                                <MenuItem key={opzione.value} value={opzione.value}>
                                  {opzione.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2} style={{ marginTop: '8px' }}>
                          <TextField
                            label="Quietanza"
                            type="date"
                            value={file.quietanza}
                            onChange={(e) => handleInput('quietanza', e.target.value, index)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              inputProps: {
                                'aria-label': 'quietanza',
                              },
                              placeholder: ' ',
                            }}
                            fullWidth
                            size="small"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ width: '100%' }}>
                        <Grid item xs={12} sm={6} md={6} lg={3} />
                        <Grid item xs={12} sm={6} md={6} lg={3} display="flex">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={file.consulenza}
                                onChange={(e) => handleInput('consulenza', e.target.checked, index)}
                              />
                            }
                            label="consulenza"
                          />
                          <TextField
                            type="number"
                            label="value"
                            variant="outlined"
                            step="any"
                            size="small"
                            value={file.value}
                            onChange={(e) => handleInput('value', e.target.value, index)}
                            inputProps={{ step: "0.1", lang: "en-US" }}
                            style={{ padding: '8px 0px 0px' }}
                          />
                        </Grid>
  
                        
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          {file.category === 'Cauzione_Provvisoria_AP' && (
                            <FormControl sx={{ m: 1, minWidth: '100%' }} size='small'>
                              <TextField
                                type="number"
                                label="durata"
                                variant="outlined"
                                size="small"
                                value={file.durata || '180'}
                                onChange={(e) => handleInput('durata', e.target.value, index)}
                              />
                            </FormControl>
                          )}
                        </Grid>*/}
                      </Grid>
                    </div>
                  </div>
                    <div className={`${styles.right_content} ${styles.content_pointer}`}>
                      <CloseIcon onClick={() => removeFileByIndex(index)} fontSize='medium' />
                  </div>
                    </div>
                      {file.error && <div className={styles.input_error} style={{ marginLeft: "20px", marginBottom: "20px" }}>
                      {file.error}
                    </div>}
                </div>
              )
            })
          }
        </div>
        
        <Flex direction="row-reverse" style={{ margin: '20px' }}>
          <Button onClick={handleUpload} isLoading={loading}>Upload files</Button>
          <Button onClick={removeAll} color="blue.60" border="none">Clear All</Button>
        </Flex>
  
      </div>
    );
  };
  
  export default Upload2iPage;