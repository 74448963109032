import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import FileUploadeCompont from '../Components/FIleManager/FIleUploader';
import Upload2iPage from '../Components/FIleManager/2iReteGasUpload'; // Importa il componente della pagina 2iretegas
import { Auth } from 'aws-amplify';


export default function Home() {
  // Simuliamo un utente autenticato
  
  //const user = {
    //email: 'jacopo.costantiniss87@gmail.com',
    // ... altre informazioni sull'utente ...
  //};

  // Stato per decidere quale componente renderizzare
  const [showPippoPage, setShowPippoPage] = useState(false);

  useEffect(() => {
    // Verifica se c'è un utente connesso
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // Ottieni l'email dell'utente connesso
        const userEmail = user.attributes.email;
  
        // Verifica l'email dell'utente e imposta lo stato
        if (userEmail === 'alex.zitti@acube.ai'  || userEmail === 'jaaaacopo.costantini87@gmail.com'|| userEmail === 'claudio.didonato@acube.ai' ) {
          setShowPippoPage(true);
        }
      })
      .catch((error) => {
        // Gestisci gli errori, ad esempio se l'utente non è autenticato
        console.error('Errore durante il recupero dell\'utente:', error);
      });
  }, []);

//export default function Home() {
  return (
    <Container maxWidth="xl" style={{ margin: "0 auto" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        {/* Mostra il componente PippoPage se showPippoPage è true */}
        {showPippoPage ? (
          <Upload2iPage />
        ) : (
          <FileUploadeCompont />
        )}
      </Paper>  
    </Container>
  )
}
